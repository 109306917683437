import { CardContainer, Info } from '@dtk/ui-components';
import { ProposalEstateValueIncreaseError } from '@dtk/query';
import { formatPercent } from '@dtk/formatter';
import { ErrorState } from '@dtk/ui-components';

export interface DTKIndexProps {
  dtkIndex: number | undefined;
  increaseValuePercentage: number | undefined;
  maxDtkIndex: number | undefined;
  dtkIndexError: ProposalEstateValueIncreaseError | undefined;
}

export const DTKIndexComponent = ({ dtkIndex, increaseValuePercentage, maxDtkIndex, dtkIndexError }: DTKIndexProps) => {
  const lines = [];

  if (
    !dtkIndex ||
    !increaseValuePercentage ||
    !maxDtkIndex ||
    (dtkIndexError && dtkIndexError === ProposalEstateValueIncreaseError.ENTITY_NOT_FOUND)
  ) {
    return (
      <CardContainer>
        <ErrorState state={'tryAgainLater'} />
      </CardContainer>
    );
  }

  for (let index = 1; index <= maxDtkIndex; index++) {
    lines.push(
      <svg width={24} height={6} className={`${dtkIndex >= index ? `fill-gold-gold` : `fill-white`}`} key={index}>
        <rect width={24} height={6} />
      </svg>
    );
  }

  return (
    <CardContainer className="h-full">
      <div>
        <div className="mb-2 flex flex-row justify-between md:mb-3">
          <h3 className="text-navy text-lg font-semibold leading-6">Prognose Zukunftspotential (DTK-Index)</h3>
          <Info
            variant="popOver"
            headline="DTK Index"
            preferedPosition="right"
            content={
              'Diese Bewertung betrachtet alle relevanten Einflüsse in Ihrer näheren Umgebung. Dazu zählen wirtschaftliche, demographische und infrastrukturelle Einflussfaktoren. Unser KI-basierter Algorithmus bewertet, vergleicht und prognostiziert Ihre Wertsteigerung im Vergleich zur durchschnittlichen Wertsteigerung in Gesamt-Deutschland. Weitere Details zu Ihren betreffenden Einflussfaktoren finden Sie in den aufgezeigten Trendcharts.'
            }
          />
        </div>
        <ol className="bg-navy-800 mb-4 rounded-xl p-8 pb-6 text-white">
          <div className="pb-6">
            <span className="mr-2 text-6xl font-bold">{dtkIndex}</span>
            <span className="text-coolGray-400 text-base font-semibold">von 7</span>
          </div>
          <div className="flex flex-row gap-2">{lines}</div>
          <p className="text-coolGray-400 pt-8 text-sm font-semibold">Wertsteigerung</p>
          <p className="text-sm font-bold text-white">{`Prognostizierte Wertentwicklung für das kommende Jahr: ${formatPercent(
            increaseValuePercentage / 100,
            2
          )}`}</p>
        </ol>
        <div>
          <span className="text-navy text-sm font-semibold">Was bedeutet der DTK-Index?</span>
        </div>
        <div>
          <span className="text-navy text-sm">
            Diese Bewertung betrachtet alle relevanten Einflüsse in Ihrer näheren Umgebung. Dazu zählen wirtschaftliche,
            demographische und infrastrukturelle Einflussfaktoren und bildet mit Hilfe von intelligenten Algorithmen
            eine Prognose für das zukünftige Potential einer Immobilienlage.
          </span>
        </div>
      </div>
    </CardContainer>
  );
};
