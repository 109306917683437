import { SocioDemographics } from '@dtk/query';
import { CardContainer, Info } from '@dtk/ui-components';
import { getSocioDemographicStats } from './getSocioDemographicStats';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface ZusammenfassungProps {
  socioData: SocioDemographics;
}

interface SocioComponentProps {
  category: {
    title: string;
    icon: JSX.Element;
    stars: ReactNode;
    infoText: string;
  };
}

const SocioComponent = ({ category }: SocioComponentProps) => (
  <Disclosure key={category.title}>
    {({ open }) => (
      <div className={classNames('bg-navy-700 mb-2 flex flex-col rounded-lg p-4 text-white xl:basis-[45%]')}>
        <Disclosure.Button>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              {category.icon}
              <div className="ml-2 flex flex-col">
                <span className="flex justify-self-start text-lg">{category.title}</span>
                <div className="flex flex-row">{category.stars}</div>
              </div>
            </div>
            <div>
              <ChevronUpIcon className={`${!open && 'rotate-180 transform'} h-5 w-5 text-white`} />
            </div>
          </div>
        </Disclosure.Button>
        <Disclosure.Panel className={`pt-3 text-sm font-normal`}>{category.infoText}</Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);

export const Zusammenfassung = ({ socioData }: ZusammenfassungProps) => {
  const numberMap = new Map<number, number>([
    [6, 0],
    [5, 1],
    [4, 2],
    [3, 3],
    [2, 4],
    [1, 5],
  ]);

  const mappedSocioData = getSocioDemographicStats(socioData, numberMap)?.filter((stat) => stat.isDataAvailable);
  const leftColData = mappedSocioData.filter((_, index) => index % 2 === 0);
  const rightColData = mappedSocioData.filter((_, index) => index % 2 !== 0);

  return (
    <CardContainer className="h-full">
      <div className="flex flex-row justify-between">
        <h3 className="text-navy mb-2 text-lg font-semibold leading-6 md:mb-3">Zusammenfassung in Schulnoten</h3>
        <Info
          variant="popOver"
          headline="Zusammenfassung"
          content={
            'Die Werte bewerten sowohl aktuelle als auch historische Entwicklungen von ausgewählten Einflussfaktoren, die sich auf den DTK-Index auswirken. Die Faktoren werden im deutschlandweiten Vergleich bewertet.'
          }
          preferedPosition="left"
        />
      </div>
      <div className="flex flex-col xl:flex-row xl:gap-x-8">
        <div className="basis-1/2">
          {leftColData.map((stat) => (
            <SocioComponent category={stat} key={stat.title} />
          ))}
        </div>
        <div className="basis-1/2">
          {rightColData.map((stat) => (
            <SocioComponent category={stat} key={stat.title} />
          ))}
        </div>
      </div>
    </CardContainer>
  );
};
