import { ReactNode } from 'react';

import {
  IconBevoelkerung,
  IconEinkommen,
  IconEinkommensEntwicklung,
  IconKriminalität,
  IconKriminalitätsEntwicklung,
  IconMietpreisEntwicklung,
  IconMietpreisNiveau,
  IconTourismus,
  IconZentralität,
  IconInfrastruktur,
  IconStarFilled,
  IconStarOutlined,
} from '@dtk/ui-components';
import { SocioDemographics } from '@dtk/query';
import classNames from 'classnames';

export const getSocioDemographicStats = (
  socioData: SocioDemographics,
  numberMap: Map<number, number>,
  starColor?: string
) => {
  function getStars(number: number): ReactNode {
    const stars = [];

    for (let index = 1; index <= number; index++) {
      stars.push(
        <IconStarFilled
          className={classNames('mr-2 h-4 w-4 border-gray-300', starColor && `[&_path]:fill-current text-${starColor}`)}
          key={`f-${index}`}
        />
      );
    }

    if (number < 5) {
      for (let index = number; index < 5; index++) {
        stars.push(
          <IconStarOutlined
            className={classNames('mr-2 h-4 w-4', starColor && `[&_path]:stroke-current text-${starColor}`)}
            key={`o-${index}`}
          />
        );
      }
    }

    return stars;
  }

  function getNumberValue(key: number | undefined) {
    if (key === undefined) {
      return 0;
    }
    return numberMap.get(key) || 1;
  }

  const availableIncome: number = getNumberValue(socioData.availableIncome?.scoringToday);
  const averageRent: number = getNumberValue(socioData.averageRent?.scoringToday);
  const criminalRate: number = getNumberValue(socioData.criminalRate?.scoringToday);

  const tourism: number = getNumberValue(socioData.hotel?.scoringToday);
  const infrastructure: number = getNumberValue(socioData.OPNV?.scoringToday);
  const centrality: number = socioData.centrality || 1; // do not need getNumberValue mapping, because it is already adjusted

  const availableIncomeIncrease: number = getNumberValue(socioData.availableIncome?.scoringIncrease);
  const averageRentIncrease: number = getNumberValue(socioData.averageRent?.scoringIncrease);
  const criminalRateIncrease: number = getNumberValue(socioData.criminalRate?.scoringIncrease);
  const populationDevelopmentIncrease: number = getNumberValue(socioData.populationDevelopment?.scoringIncrease);

  const stats = [
    {
      icon: <IconBevoelkerung className="my-auto mx-2 h-7 w-7" />,
      title: 'Bevölkerungsentwicklung',
      stars: getStars(populationDevelopmentIncrease),
      isDataAvailable: populationDevelopmentIncrease !== 0,
      infoText:
        'Relative Entwicklung der Anzahl der Einwohner innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconZentralität className="my-auto mx-2 h-7 w-7" />,
      title: 'Zentralität',
      stars: getStars(centrality),
      isDataAvailable: centrality !== 0,
      infoText:
        'Einteilung der Verbandsgemeinden in Stadt- und Gemeindetypen nach Einwohnerzahl, zentralörtlichen Funktionen und siedlungsstruktureller Prägung.',
    },
    {
      icon: <IconEinkommen className="my-auto mx-2 h-7 w-7" />,
      title: 'Verfügbares Einkommen',
      stars: getStars(availableIncome),
      isDataAvailable: availableIncome !== 0,
      infoText:
        'Höhe des durchschnittlich verfügbaren Einkommens innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconInfrastruktur className="my-auto mx-2 h-7 w-7" />,
      title: 'Infrastruktur',
      stars: getStars(infrastructure),
      isDataAvailable: infrastructure !== 0,
      infoText:
        'Angebot des öffentlichen Personennahverkehrs innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconEinkommensEntwicklung className="my-auto mx-2 h-7 w-7" />,
      title: 'Einkommensentwicklung',
      stars: getStars(availableIncomeIncrease),
      isDataAvailable: availableIncomeIncrease !== 0,
      infoText:
        'Relative Entwicklung des durchschnittlichen verfügbaren Einkommens innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconTourismus className="my-auto mx-2 h-7 w-7" />,
      title: 'Tourismus',
      stars: getStars(tourism),
      isDataAvailable: tourism !== 0,
      infoText: 'Anzahl an Gästeübernachtungen innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconMietpreisEntwicklung className="my-auto mx-2 h-7 w-7" />,
      title: 'Mietpreisentwicklung',
      stars: getStars(averageRentIncrease),
      isDataAvailable: averageRentIncrease !== 0,
      infoText:
        'Relative Entwicklung des Brutto-Kaltmietpreises innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconKriminalität className="my-auto mx-2 h-7 w-7" />,
      title: 'Kriminalitätsrate',
      stars: getStars(criminalRate),
      isDataAvailable: criminalRate !== 0,
      infoText:
        'Anzahl der Straftaten innerhalb einer Postleitzahl im deutschlandweiten Vergleich. (Je geringer, desto höher die Bewertung.)',
    },
    {
      icon: <IconMietpreisNiveau className="my-auto mx-2 h-7 w-7" />,
      title: 'Aktuelles Mietpreisniveau',
      stars: getStars(averageRent),
      isDataAvailable: averageRent !== 0,
      infoText:
        'Höhe des durchschnittlichen Brutto-Kaltmietpreises innerhalb einer Postleitzahl im deutschlandweiten Vergleich.',
    },
    {
      icon: <IconKriminalitätsEntwicklung className="my-auto mx-2 h-7 w-7" />,
      title: 'Kriminalitätsentwicklung',
      stars: getStars(criminalRateIncrease),
      isDataAvailable: criminalRateIncrease !== 0,
      infoText:
        'Relative Entwicklung der Straftatenanzahl innerhalb einer Postleitzahl im deutschlandweiten Vergleich. (Je geringer, desto höher die Bewertung.)',
    },
  ];

  return stats;
};
