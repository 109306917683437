import { useTailwind, useMediaQuery, Card } from '@dtk/ui-components';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveCardinal } from '@visx/curve';
import { Legend } from '@dtk/data-visualization';
import { localPoint } from '@visx/event';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath, Line, Circle } from '@visx/shape';
import { Text } from '@visx/text';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';

import { bisector } from 'd3-array';
import { MouseEvent, TouchEvent, useState } from 'react';

import { Tooltip, TooltipProps } from './Tooltip';
import {
  ChartLineValues,
  formatTimeAxis,
  getX,
  getY,
  getXAxisDomainValues,
  getYAxisDomainValues,
  getNumTicksAxisBottom,
  ChartDataSet,
} from './utils';

export type DateBasedChartProps = {
  chartHeadline: string;
  chartDataSets: ChartDataSet[];
};

export const DateBasedChartComponent = ({ chartHeadline, chartDataSets }: DateBasedChartProps) => {
  const margin = 32;
  const { colors } = useTailwind();
  const { smallerXs, smallerSm, largerSm } = useMediaQuery();

  const X_SCALE = 1200;
  const Y_SCALE = 450;

  //TODO: Change type useTailwind
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colorsArray = [colors?.gold?.sand, colors?.cyan[700], colors?.navy[300]];

  const innerWidth = window.innerWidth < X_SCALE ? window.innerWidth - margin * 2 : X_SCALE - margin * 2;
  const innerHeight = Y_SCALE - margin * 2;

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<TooltipProps>();
  const [coordinateForSecondLine, setCoordinateForSecondLine] = useState([0, 0]);

  const bisectX = bisector<ChartLineValues, Date>((data: ChartLineValues) => new Date(getX(data))).left;

  const xAxisScale = scaleTime<number>({
    domain: getXAxisDomainValues(chartDataSets),
    range: [0, innerWidth],
  });

  const yAxisScale = scaleLinear<number>({
    domain: getYAxisDomainValues(chartDataSets).map((yValue) => {
      return yValue * 100;
    }),
    range: [innerHeight, 0],
  });

  const xScale = scaleTime<number>({
    domain: getXAxisDomainValues(chartDataSets),
    range: [0 + margin, innerWidth + margin],
  });

  const yScale = scaleLinear<number>({
    domain: getYAxisDomainValues(chartDataSets),
    range: [innerHeight + margin, 0 + margin],
  });

  const numTicksAxisBottom = getNumTicksAxisBottom(chartDataSets);
  let tooltipTimeout: number;

  const handleTooltip = (event: TouchEvent | MouseEvent) => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }

    const coords = localPoint(event);
    if (!coords) {
      return;
    }

    const x0 = xScale.invert(coords.x);
    const index = bisectX(chartDataSets[0].chartLineValues, x0, 1);

    const values = chartDataSets.map((chartDataSet) => {
      return chartDataSet.chartLineValues[index - 1];
    });

    const d = values[0];

    showTooltip({
      tooltipData: { year: d.year, values: values.map((value) => value.value) },
      tooltipLeft: xScale(getX(d)),
      tooltipTop: yScale(getY(d)),
    });

    setCoordinateForSecondLine([xScale(getX(values[1])), yScale(getY(values[1]))]);
  };

  return (
    <div className="relative">
      <svg className="select-none" height="400" viewBox={`0 0 ${innerWidth + margin * 2} ${Y_SCALE}`}>
        <Text x={margin * 0.2} y={margin * 0.5} textAnchor="start" className="text-navy font-semibold">
          {chartHeadline}
        </Text>
        <GridColumns scale={xAxisScale} left={margin} top={margin} height={innerHeight} strokeDasharray={'5'} />
        <AxisLeft
          scale={yAxisScale}
          left={margin * 1.25}
          top={margin}
          hideAxisLine={true}
          hideTicks={true}
          numTicks={4}
          tickFormat={(label) => label.toLocaleString()}
          tickLabelProps={() => ({
            fontSize: 16,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fill: colors?.coolGray[800],
            textAnchor: 'end',
            dx: '-10',
          })}
        />
        <AxisBottom
          scale={xAxisScale}
          left={margin}
          top={Y_SCALE - margin}
          hideAxisLine={true}
          hideTicks={true}
          numTicks={smallerXs ? numTicksAxisBottom / 2 : numTicksAxisBottom}
          tickFormat={formatTimeAxis}
          tickLabelProps={() => ({
            fontSize: 16,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fill: colors?.coolGray[800],
            textAnchor: 'middle',
          })}
        />
        {chartDataSets.map((dataSet, index) => (
          <LinePath<ChartLineValues>
            curve={curveCardinal}
            data={dataSet.chartLineValues}
            x={(d) => xScale(getX(d))}
            y={(d) => yScale(getY(d))}
            stroke={colorsArray[index]}
            strokeWidth={3}
            key={dataSet.chartLineName}
          />
        ))}

        {/* thicker invisible line to hover for Tooltip */}
        <LinePath<ChartLineValues>
          curve={curveCardinal}
          data={chartDataSets[0].chartLineValues}
          x={(d) => xScale(getX(d))}
          y={(d) => yScale(getY(d))}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stroke={colors?.gold?.sand}
          strokeOpacity={0}
          strokeWidth={1000}
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => {
            tooltipTimeout = window.setTimeout(() => {
              hideTooltip();
            }, 300);
          }}
        />
        {tooltipOpen && tooltipData && (
          <>
            <Line
              from={{ x: tooltipLeft, y: innerHeight }}
              to={{ x: tooltipLeft, y: Math.min(tooltipTop || 0, coordinateForSecondLine[1]) }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              stroke={colors?.black}
              strokeWidth={1}
              strokeDasharray={5}
            />
            {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
            <Circle r={8} fill={colors?.white} cx={tooltipLeft} cy={tooltipTop} />
            {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
            <Circle r={6} fill={colors?.gold?.sand} cx={tooltipLeft} cy={tooltipTop} />
            {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
            <Circle r={8} fill={colors?.white} cx={coordinateForSecondLine[0]} cy={coordinateForSecondLine[1]} />
            {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
            <Circle r={6} fill={colors?.cyan[700]} cx={coordinateForSecondLine[0]} cy={coordinateForSecondLine[1]} />
          </>
        )}
      </svg>
      {tooltipOpen && tooltipData && largerSm && (
        <>
          {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
          <TooltipWithBounds key={Math.random()} top={tooltipTop || 0} left={tooltipLeft || 0}>
            <Tooltip {...tooltipData} />
          </TooltipWithBounds>
        </>
      )}
      <div className="mt-4 grid justify-items-end">
        <Legend
          alignment="horizontal"
          items={chartDataSets.map((set) => ({
            id: set.chartLineName,
            label: set.chartLineName,
            color: set.chartLineColor,
          }))}
        />
      </div>
      {tooltipOpen && tooltipData && smallerSm && (
        <Card className="shadow-md">
          <Tooltip {...tooltipData} />
        </Card>
      )}
    </div>
  );
};
