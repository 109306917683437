import classNames from 'classnames';
import { formatPercent } from '@dtk/formatter';

export interface TooltipProps {
  year: number;
  values: number[];
}

const defaultColors = ['bg-gold-sand', 'bg-cyan-700'];

export const Tooltip = ({ year, values }: TooltipProps) => {
  return (
    <div className="border-rounded text-navy p-1 text-sm">
      {year && <p className="pb-1 font-bold">Relatives Wachstum für {year}</p>}
      {values.map((value, index) => (
        <div className="flex items-center" key={index}>
          <div className={classNames(defaultColors[index], 'w-3.5', 'h-1', 'my-2.5 mr-2')} />
          <p className="pb-1 leading-4">{formatPercent(value)}</p>
        </div>
      ))}
    </div>
  );
};
