import { Card, ErrorState, Spinner, Tab, Tabs } from '@dtk/ui-components';
import { SocioTrendRating, useGetProposalEstateValueIncrease, useGetSocioDemographics } from '@dtk/query';

import { ChartLineValues } from './graphs/utils';
import { DTKIndexComponent } from './DTKIndex';
import { DateBasedChartComponent } from './graphs/DateBasedChartComponent';
import { Zusammenfassung } from './Zusammenfassung';
import { useState } from 'react';

type SociodemographicsTab =
  | 'Mietpreisentwicklung'
  | 'Verfügbares Einkommen'
  | 'Kriminalität'
  | 'Bevölkerungsentwicklung';

const sociodemographicsTabs: Tab[] = [
  { name: 'Mietpreisentwicklung', current: true },
  { name: 'Verfügbares Einkommen', current: false },
  { name: 'Kriminalität', current: false },
  { name: 'Bevölkerungsentwicklung', current: false },
];

interface SociodemographicProps {
  chosenSocioData: SocioTrendRating;
}

const SpecificSociodemographicChart = ({ chosenSocioData }: SociodemographicProps) => {
  return (
    <DateBasedChartComponent
      chartHeadline="Wachstum in %"
      chartDataSets={[
        {
          chartLineName: 'In Ihrer Postleitzahl',
          chartLineValues: chosenSocioData.perZip as ChartLineValues[],
          chartLineColor: 'bg-gold-sand',
        },
        {
          chartLineName: 'Deutschland',
          chartLineValues: chosenSocioData.perCountry as ChartLineValues[],
          chartLineColor: 'bg-cyan-700',
        },
      ]}
    />
  );
};

export function Sociodemographics() {
  const { data, isError, isLoading } = useGetProposalEstateValueIncrease();
  const { data: socioData, isError: isSocioError, isLoading: isSocioLoading } = useGetSocioDemographics();

  const [currentTab, setCurrentTab] = useState<SociodemographicsTab>('Mietpreisentwicklung');

  if (isLoading || isSocioLoading) {
    return <Spinner />;
  }

  if (isError || !data?.data || !socioData?.data || isSocioError) {
    return <ErrorState state={'noDataAvailable'} />;
  }

  const indexData = data.data;
  const { dtkIndex, increaseValuePercentage, maxDtkIndex, error } = indexData;
  const { populationDevelopment, criminalRate, averageRent, availableIncome } = socioData.data;

  return (
    <div>
      <Card headline="Aktuelle Bewertung der Einflussfaktoren">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div>
            <DTKIndexComponent
              dtkIndex={dtkIndex}
              increaseValuePercentage={increaseValuePercentage}
              maxDtkIndex={maxDtkIndex}
              dtkIndexError={error}
            />
          </div>
          <div className="lg:col-span-2">
            <Zusammenfassung socioData={socioData.data} />
          </div>
        </div>
      </Card>

      <div className="mt-10">
        <h3
          className="inline-flex rounded-t bg-white py-2 px-4 font-semibold"
          style={{ boxShadow: '0 -2px 6px -1px rgb(0 0 0 / 0.1)' }}
        >
          {'Demografie'}
        </h3>
        <Card tabBarTitle="Demographie" className="rounded-tl-none">
          <div className="mb-10">
            <Tabs
              tabs={mapCurrentTabToSociodemoraphicsTabs(currentTab)}
              handleCurrent={(selectedTab) => setCurrentTab(selectedTab as SociodemographicsTab)}
            />
          </div>
          {currentTab === 'Bevölkerungsentwicklung' && populationDevelopment && (
            <SpecificSociodemographicChart chosenSocioData={populationDevelopment} />
          )}
          {currentTab === 'Kriminalität' && criminalRate && (
            <SpecificSociodemographicChart chosenSocioData={criminalRate} />
          )}
          {currentTab === 'Verfügbares Einkommen' && availableIncome && (
            <SpecificSociodemographicChart chosenSocioData={availableIncome} />
          )}
          {currentTab === 'Mietpreisentwicklung' && averageRent && (
            <SpecificSociodemographicChart chosenSocioData={averageRent} />
          )}
        </Card>
      </div>
    </div>
  );
}

const mapCurrentTabToSociodemoraphicsTabs = (currentTab: SociodemographicsTab): Tab[] => {
  return sociodemographicsTabs.map((tab) => {
    if (tab.name === currentTab) {
      return { ...tab, current: true };
    } else {
      return { ...tab, current: false };
    }
  });
};
