import { NumberValue } from 'd3-scale';

export const formatTimeAxis = (value: Date | NumberValue): string => {
  const date = new Date(value.toString());
  return date.getFullYear().toString();
};

export type ChartLineValues = {
  year: number;
  value: number;
};

export interface ChartDataSet {
  chartLineName: string;
  chartLineValues: ChartLineValues[];
  chartLineColor: string;
}

export const getX = (data: ChartLineValues) => new Date(data.year.toString());
export const getY = (data: ChartLineValues) => data.value;

export const getDomainValues = (chartDataSets: ChartDataSet[], propName: 'year' | 'value'): number[] => {
  const getValue = (data: ChartLineValues) => data[propName];

  const minValues: number[] = chartDataSets.map((chartDataSet) => {
    return Math.min(...chartDataSet.chartLineValues.map((data: ChartLineValues) => getValue(data)));
  });

  const maxValues: number[] = chartDataSets.map((chartDataSet) => {
    return Math.max(...chartDataSet.chartLineValues.map((data: ChartLineValues) => getValue(data)));
  });

  const minValue = Math.min(...minValues);
  const maxValue = Math.max(...maxValues);
  return [minValue, maxValue];
};

export const getXAxisDomainValues = (chartDataSets: ChartDataSet[]): Date[] => {
  const offsetXInDays = 90; // Added for small indent according to design
  const offsetXinMilliseconds = offsetXInDays * 24 * 60 * 60 * 1000;

  const values = getDomainValues(chartDataSets, 'year');

  const minDate = new Date(new Date(values[0].toString()).getTime() - offsetXinMilliseconds);
  const maxDate = new Date(new Date(values[1].toString()).getTime());

  return [minDate, maxDate];
};

export const getYAxisDomainValues = (chartDataSets: ChartDataSet[]): number[] => {
  const minValue = getDomainValues(chartDataSets, 'value')[0];
  const maxValue = getDomainValues(chartDataSets, 'value')[1];

  const axisMinValue = minValue + minValue * 0.5;
  const axisMaxValue = maxValue + maxValue * 0.5;

  return [axisMinValue, axisMaxValue];
};

export const getNumTicksAxisBottom = (chartDataSets: ChartDataSet[]): number => {
  const lengthes = chartDataSets.map((chartDataSet) => {
    return chartDataSet.chartLineValues.length;
  });

  return Math.max(...lengthes);
};
